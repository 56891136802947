<template>
    <div class="bar" v-bind:class="type">
        <div class="bar-inner" v-bind:style="{width: (100 * ((value - min) / (max - min))) + '%'}"></div>
    </div>
</template>

<script>
    export default {
        name: "bar",
        props:{
            min: Number,
            max: Number,
            value: Number,
            type: String,
        }
    }
</script>

<style scoped>
    .bar{
        position: relative;
        height: 6px;
        min-width: 20px;
        border-radius: 100px;
        overflow: hidden;
        background: var(--contrast-3);
    }
    .bar-inner{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0px;
        background: #6c8d25;
        border-radius: 100px;
    }

    .bar.red{
        background: #e5154f;
    }
    .row:hover .bar{
        background: var(--contrast-1);
    }
</style>